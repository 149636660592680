<!--
 * @Description: 商品菜单
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-12 07:58:08
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar';
export default {
  components: {
    SideBar,
  },

  data() {
    return {
      groups: [
        {
          label: '产品展示',
          list: [
            {
              name: '产品分类',
              path: '/mall/goods/group-manager',
            },
            {
              name: '产品列表',
              path: '/mall/goods/goods-manager',
            },
          ],
        },
      ],
    };
  },
};
</script>
